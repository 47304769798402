import { createRoot } from "react-dom/client";
import React from "react";
import ReactDOM from "react-dom";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import theme from "@onnit-js/ui/components/themes/light";
import BuyBox, { BuyBoxProps } from "@onnit-js/ui/components/buybox/BuyBox";
import usePageData from "../../hooks/usePageData";
import DefaultProductStickys, { useShowProductStickys, } from "../../components/stickys/DefaultProductStickys";
import StickyHeader from "../../components/common/StickyHeader";

export type BuyAreaProps = Omit<
    BuyBoxProps,
    "products" | "freeShippingThreshold"
>;

const stickyHeaderContainer = document.getElementById("react-sticky-header");
const BuyArea = (props: BuyAreaProps) => {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    const { showSticky, buyBoxRef } = useShowProductStickys();

    if (!pageData || !onnitContext) {
        return null;
    }

    return (
        <ThemeProvider theme={theme}>
            {stickyHeaderContainer
                && ReactDOM.createPortal(<StickyHeader />, stickyHeaderContainer)}

            {showSticky && (
                <DefaultProductStickys
                    products={pageData.products}
                    productName={pageData.name}
                    rating={pageData.rating}
                />
            )}

            <BuyBox
                buyBoxRef={buyBoxRef}
                products={pageData.products}
                freeShippingThreshold={pageData.freeShippingThreshold}
                promoDiscountConfigs={pageData.promoDiscounts}
                productBuyBoxSelectVariant={pageData.productBuyBoxSelectVariant}
                priceSpiderHeroSku={pageData.priceSpiderHeroSku}
                {...props}
            />
        </ThemeProvider>
    );
};

window.ONNIT = window.ONNIT || {};

window.ONNIT.buyarea = {
    createBuyArea(props?: BuyAreaProps) {
        const buyAreaRoot = document.getElementById("oui-react-buyarea");
        if (buyAreaRoot) {
            const root = createRoot(buyAreaRoot);
            root.render(<BuyArea {...props} />);
        } else {
            console.error(
                "Failed to mount BuyArea because a root element #oui-react-buyarea wasn't defined.",
            );
        }
    },
};
